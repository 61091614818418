import HeaderContainer_ShadowBottomScreenComponent from '@wix/thunderbolt-elements/src/components/HeaderContainer/viewer/skinComps/ShadowBottomScreen/ShadowBottomScreen.skin';
import HeaderContainer_ShadowBottomScreenController from '@wix/thunderbolt-elements/src/components/HeaderContainer/viewer/HeaderContainer.controller';


const HeaderContainer_ShadowBottomScreen = {
  component: HeaderContainer_ShadowBottomScreenComponent,
  controller: HeaderContainer_ShadowBottomScreenController
};


export const components = {
  ['HeaderContainer_ShadowBottomScreen']: HeaderContainer_ShadowBottomScreen
};

